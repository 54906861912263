// Couleurs principales
$blue1: #199fe2;
$blue2: #102c3a;
$blue3: #146187;
$blue4: #11384b;
$blue5: #035a77;
$blue_header: #0c81ae;
$orange: #ff7200;
$orange2: #ff5a00;
$orange3: #fda804;
$green1: #01a59b;
$green2: #ccedeb;
$green3: #007a73;
$grey1: #424242;
$grey2: #a8a8a8;
$grey3: #f1f2f4;
$grey4: #f8f8f8;
$rose1: #f36;
$red1: #e60000;
$purple: #955099;

// Types de contenus
$separator: #cbcbcb;

// OLD
// $darkwhite: #e0e0e0;

// $dark-blue: #102c3a;
// $blue1: #199FE2;

// $green: #01A59B;
// $light-green: #01a59c;

// $magenta: #ff3469;
// $red: #e60000;

// $grey2: #a8a8a8;
