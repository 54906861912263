@import url('https://cdn.easi-training.fr/assets/css/icons.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700');

.icon-search:before {
    content: '\e900';
}

.icon-close:before {
    content: '\e905';
}

.icon-plus:before {
    content: '\e904';
}

.icon-minimizebloc:before {
    content: '\e92e';
}

.icon-see:before {
    content: '\e925';
}

.icon-dropdown:before {
    content: '\e92f';
}

.icon-interactivevideo-circle:before {
    content: '\e9d1';
}

.icon-interactivevideo:before {
    content: '\e9cc';
}

.icon-machine:before {
    content: '\ea31';
}

.icon-quiztrain::before {
    content: '\e91c';
}

.icon-quiztrain-circle::before {
    content: '\e960';
}

.icon-evaluation:before {
    content: '\e91c';
}

.icon-timeline:before {
    content: '\ea02';
}

.icon-timeline-circle:before {
    content: '\ea03';
}

.icon-coursepresentation:before {
    content: '\e9e2';
}

.icon-coursepresentation-circle:before {
    content: '\ea00';
}

.icon-clone:before {
    content: '\e93e';
}

.icon-bin:before {
    content: '\e903';
}

.icon-send:before {
    content: '\e94f';
}

.icon-edit:before {
    content: '\e936';
}

.icon-activity:before {
    content: '\e918';
}

.icon-internalteacher:before {
    content: '\e909';
}

.icon-prev:before {
    content: '\e987';
}

.icon-next:before {
    content: '\e986';
}

.icon-warning:before {
    content: '\e958';
}

.icon-publish::before {
    content: '\e93c';
}

.icon-assembly::before {
    content: '\e931';
}

.icon-waiting::before {
    content: '\e955';
}

.icon-stamp::before {
    content: '\e90f';
}

.icon-training::before {
    content: '\e9b7';
}
